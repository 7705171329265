<template>
  <div>
    <v-container pa-2 fluid grid-list-md v-if="post" v-show="!loading">
      <v-layout row wrap>
        <v-flex xs12>
          <img
            class="img"
            :src="post.images[0].original"
            alt=""
            @click="dialog = true"
          />
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card color="grey darken-4">
              <v-flex xs12>
                <v-btn icon dark @click.native="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 text-sm-center class="center">
                <!-- <v-carousel class="carousel-img" hide-delimiters >
                <v-carousel-item v-for="(image,i) in post.images"  :key="i">
                  <img class="img2" :src="image.original" alt="">
                </v-carousel-item>
              </v-carousel> -->
                <v-carousel hide-delimiters height="auto">
                  <v-carousel-item
                    v-for="(image, i) in post.images"
                    :key="i"
                    :src="image.original"
                    reverse-transition="fade"
                    transition="fade"
                    @click="dialog = true"
                  ></v-carousel-item>
                </v-carousel>
              </v-flex>
            </v-card>
          </v-dialog>
          <v-layout row wrap>
            <v-flex xs12 py-2>
              <span class="title">{{ post.title }}</span>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 d-inline-flex>
              <v-card>
                <v-card-text>
                  <b>{{ $t('description') }}</b>
                  <div v-html="post.des"></div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 d-inline-flex>
              <v-card>
                <v-card-text>
                  <b>{{ $t('term-and-condition') }}</b>
                  <div v-html="post.tnc"></div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      post: null,
      errors: [],
      dialog: false,
    };
  },
  beforeMount() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(
          `https://balitripcenter.com/api/specialoffer/` +
            this.$route.params.slug
        )
        .then((response) => {
          this.loading = false;
          this.post = response.data.items;
          //console.log(response.data.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
.img {
  width: 100%;
  height: auto;
  align-content: center;
  display: block;
  object-fit: cover;
}

.img2 {
  width: 100%;
  height: 100%;
  align-content: center;
  display: block;
  object-fit: scale-down;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-center {
  vertical-align: middle;
  line-height: 30px;
}
.h-center {
  text-align: center;
}
.right {
  text-align: right;
}
</style>