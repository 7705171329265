<template>
    <div class="review-container">
        <div class="review-section-title">
            <h4 style="line-height: 0;font-weight: normal;">{{$t('What they say about')}}</h4>
            <h1>Bali Trip Center</h1>
        </div>
        <div v-if="!loading" style="padding-top: 130px;">
            <div class="review-total-score d-flex">
                <div>
                    <h3 style="border-left: 3px solid #000;padding-left: 10px;">{{$t('Customer reviews')}}</h3>
                    <br>
                    <h1>{{stats.average_rating}} <small>/5</small></h1>
                </div>
                <div class="text-right">
                    <v-btn to="activity" outlined elevation="0" color="dark" style="padding: 0 6px;">{{ $t('All reviews') }}</v-btn><br>
                    <br>
                    <h3 style="line-height: 10px;">{{ $t('Review') }}</h3>
                    <small>{{$t('_from')}} {{stats.total_reviews}} {{$t('Customer reviews')}}</small>
                </div>
            </div>
            <div v-if="items && items.length">
                <v-container fluid grid-list-md pa-1>
                    <v-layout row wrap>
                        <div class="scrolling-wrapper">
                        <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
                            <home-review-card
                            class="card"
                            :post="post"
                            ></home-review-card>
                        </v-flex>
                        </div>
                    </v-layout>
                </v-container>
            </div>
            <center class="mt-1">
                <v-btn to="activity" elevation="0" dark block>{{ $t('Write Review') }}</v-btn>
            </center>
            <loading :loading="loading"></loading>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      stats: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/comments`)
        .then((response) => {
          this.loading = false;
          this.stats = response.data.data;
          this.items = response.data.items;
          //console.log(response.data.category)
        })
        .catch((e) => {
          this.loading = false;
          // this.errors.push(e)
        });
    },
  },
};
</script>
<style scoped>
.review-container {
    position: relative;
}
.review-section-title {
    padding: 15px;
    padding-bottom: 30px;
    padding-top: 40px;
    background-color: #e5f3fe;
    position: absolute;
    top: -10px;
    left: -7px;
    width: calc(100% + 22px);
}
.review-total-score {
    justify-content: space-between;
}
.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
</style>