<template>
  <div>
    <nav-header></nav-header>
    <v-flex xs12 ma-2 elevation-1>
      <v-tabs v-if="items.length" :height="34" show-arrows mb-2>
        <v-tabs-slider color="deep-orange"></v-tabs-slider>

        <v-tab v-for="item in items" :key="item.id" @click="selected(item.id)">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-flex>
    <v-container pa-2 v-if="category" v-show="!loading" fluid grid-list-md>
      <v-layout row wrap>
        <v-flex xs12 text-center>
          <span class="block">{{ category.name }}</span
          ><br />
          <read-more
            :more-str="$t('read-more')"
            :less-str="$t('read-less')"
            :text="category.title"
            :max-chars="80"
          ></read-more>
        </v-flex>
      </v-layout>
    </v-container>
    <div v-if="posts && posts.length">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(post, i) of posts" :key="i" xs12 sm6 xl4>
            <destination-card :post="post"></destination-card>
          </v-flex>
        </v-layout>
      </v-container>
      <div class="text-center my-2" v-if="!loading && currentPage != null">
        <v-btn rounded class="white--text" color="green" @click="getPosts"
          >{{ $t('load-more') }}</v-btn
        >
      </div>
    </div>
    <v-flex xs12 v-else v-show="!loading" ma-2 text-center>
      <span>{{ $t('this-category-doesnt-have-destination-yet') }}</span>
    </v-flex>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
const api = "https://balitripcenter.com/api/regency";

export default {
  data() {
    return {
      loading: false,
      posts: [],
      errors: [],
      currentPage: api,
      items: [
        {
          id: null,
          name: this.$t('all-destination'),
          des: this.$t('all-available-destinations-at-our-site'),
          title:
            this.$t('plan-your-tour-to-visit'),
          created_at: this.$t('2018-07-09-08-28-57'),
          updated_at: this.$t('2018-07-09-08-28-57'),
        },
      ],
      category: null,
    };
  },
  // mounted () {
  //   this.scroll()
  // },
  beforeMount() {
    this.getCategories();
    this.getPosts();
  },
  methods: {
    // scroll () {
    //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
    //   if (bottomOfWindow && !this.loading && this.currentPage) {
    //     this.loadMore()
    //   }
    // },
    selected(id) {
      this.posts = [];
      if (id == null) {
        this.currentPage = "https://balitripcenter.com/api/regency";
      } else {
        this.currentPage = "https://balitripcenter.com/api/regency/" + id;
      }
      this.getPosts();
    },
    getCategories() {
      axios
        .get("https://balitripcenter.com/api/regencies")
        .then((response) => {
          this.items = this.items.concat(response.data.items);
        })
        .catch((e) => {});
    },
    getPosts() {
      this.loading = true;
      axios
        .get(this.currentPage)
        .then((response) => {
          this.loading = false;
          this.posts = this.posts.concat(response.data.items.data);
          this.currentPage = response.data.items.next_page_url;
          this.category = response.data.category;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.block {
  background: url("~@/assets/images/background_down_xs.png") no-repeat;
  background-position: center;
  background-size: 100% auto;
  margin: 4px;
  font-size: 20px;
  width: auto;
  object-position: center;
  color: white;
  display: block;
}
</style>