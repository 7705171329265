<template>
  <div>
    <v-card
      text
      class="black--text round-bg"
      :to="{ name: 'specialoffer', params: { slug: post.slug } }"
    >
      <v-img
        class="round-img"
        :src="post.images[0].thumbnail"
        :alt="post.images[0].thumbnail"
        height="130px"
        width="100%"
      >
      </v-img>
      <v-card-text>
        <b class="black--text">{{ post.title }}</b
        ><br />
        {{ post.short_des }}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.round-bg {
  border-radius: 14px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #ddd;
}

.round-img {
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  background-color: white;
  box-shadow: #37464d 3px;
  object-position: center;
}
.fill {
  min-height: 50px;
}
.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

</style>
