<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex d-flex xs12 pa-4>
        <faq />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
</style>