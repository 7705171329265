<template>
  <v-container fluid mb-1 pa-0>
    <v-layout row wrap>
      <v-flex xs12 class="text-center">
        <div>
          <span class="title green--text">{{ $t('average-rating') }}</span><br />
          <span class="display-1">{{ $t('avg', [avg]) }}</span><br />
          <div style="display: inline-block">
            <v-rating
              v-model="avg"
              background-color="gray"
              color="yellow accent-4"
              dense
              size="20"
            ></v-rating>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  props: ["avg"],
};
</script>
<style scoped>
</style>

