<template>
  <div>
    <v-card
      elevation="4"
      class="rounded black--text"
      :to="{ name: 'activity', params: { slug: post.slug,type: post.type.slug } }"
    >
      <v-img
        :src="post.images[0].thumbnail"
        :alt="post.images[0].thumbnail"
        height="130px"
        width="100%"
      >
      </v-img>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <div>
              <div class="a-title">{{ post.name }}</div><br>
              <v-icon small color="grey lighten-2">mdi-map-marker</v-icon>
              <small class="caption" style="color: grey">{{
                post.location
              }}</small
              ><br />
            </div>
            <v-divider class="my-1"></v-divider>
            <div>
              <small class="caption" style="color: gray">{{ $t('start-from') }}</small>
              <span style="color: #FF6100" class="title float-right">{{
                $root.$i18n.locale == "id"
                  ? "IDR " : "USD "
              }}{{$root.$i18n.locale == "id"
                  ? post.price_idr : post.price_usd | fm }}</span>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.fill {
  min-height: 50px;
}
.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.rounded {
  min-height: 300px;
  border-radius: 14px 14px 0 0 !important;
}

.a-title {
  color: black;
  font-size: 16px;
  font-weight: 500;
  min-height: 50px;
}
</style>
