<template>
  <div>
    <v-card elevation="0">
      <v-flex xs12 text-center mt-2>
        <img
          src="@/assets/images/driver_header.png"
          height="60px"
          width="140px"
          alt="$t('balitripcenter')"
          style="margin-top: 1rem"
        /><br />
      </v-flex>
      <v-list three-line>
        <div
          v-for="(item, index) in items"
          :key="index"
          style="margin-top: 2rem; margin-bottom: 2rem"
        >
          <v-list-item :key="item.title">
            <v-list-item-avatar size="90" style="margin-top: 0.1rem">
              <img :src="item.icon" />
            </v-list-item-avatar>
            <v-list-item-content style="margin-left: 1rem" class="box-gray">
              <v-list-item-title>
                <span caption class="orange--text">{{ item.name }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <i>{{ item.age }}</i
                ><br />
                <span
                  ><img
                    class="smallicon"
                    src="@/assets/images/icon_english.png"
                    alt="english"
                  />{{ item.lang }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card>
    <v-card elevation="0" class="text-center" style="margin-top: 20px">
      <div class="box">
        <img
          class="hero-icon"
          src="@/assets/images/ic_driver.webp"
          alt="english"
        /><br />
        <b>{{ $t('driver-bali-trip-center') }}</b>
        <read-more
          class="text-gray" 
          :more-str="$t('read-more')"
          :less-str="$t('read-less')"
          :text="driver"
          :max-chars="250"
        ></read-more>
      </div>
      <v-divider></v-divider>
      <div class="box">
        <img
          class="hero-icon"
          src="@/assets/images/ic_faq.webp"
          alt="english"
        /><br />
        <b>{{ $t('why-travel-with-us') }}</b>
        <read-more
          class="text-gray"
          :more-str="$t('read-more')"
          :less-str="$t('read-less')"
          :text="reason"
          :max-chars="250"
        ></read-more>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  /* eslint-disable */
  data() {
    return {
      items: [
        {
          icon: require("@/assets/images/driver/dewa_arimbawa_xs.png"),
          name: this.$t('dewa-arimbawa'),
          age: this.$t('32-years-old'),
          lang: this.$t('english-speaking-guide'),
        },
        {
          icon: require("@/assets/images/driver/july_bali_xs.png"),
          name: this.$t('juli-bali'),
          age: this.$t('35-years-old'),
          lang: this.$t('english-speaking-guide'),
        },
        {
          icon: require("@/assets/images/driver/indra_prayoga_xs.png"),
          name: this.$t('dewa-indra'),
          age: this.$t('23-years-old'),
          lang: this.$t('english-speaking-guide'),
        },
        {
          icon: require("@/assets/images/driver/alit_putra_xs.png"),
          name: this.$t('alit-putra'),
          age: this.$t('35-years-old'),
          lang: this.$t('english-speaking-guide'),
        },
      ],
      driver:
        this.$t('our-drivers-will-help-you-make'),
      reason:
        this.$t('we-will-give-you-a-chance'),
    };
  },
};
</script>
<style scoped>
.smallicon {
  margin-right: 4px;
  width: 14px;
  height: auto;
}
.hero-icon {
  width: 40px;
  height: auto;
}
.box {
  padding: 12px;
}

.box-gray {
  background-color: #f7f7f5;
  padding: 12px;
}
.text-gray {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
</style>