<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex d-flex xs12 md6>
        <img
          class="carousel-img"
          src="@/assets/images/about.jpg"
          alt="$t('about-us')"
        />
      </v-flex>
      <v-flex d-flex xs12 md6>
        <v-layout row wrap>
          <v-flex d-flex>
            <p>
              {{ $t('bali-trip-center-is') }} </p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

p {
  text-align: justify;
}
</style>