<template>
  <div>
    <div class="gallery" v-if="photos && photos.length" v-show="!loading">
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs8>
            <div class="headline">{{ $t('photo-gallery') }}</div>
            <div class="lead">{{ $t('our-happy-customer') }}</div>
            <v-btn
              class="mt-2"
              outlined
              rounded
              :to="{ name: 'gallery' }"
              small
              roundedoutline
              ><v-icon>mdi-image</v-icon>{{ $t('view-more') }}</v-btn
            >
          </v-flex>
          <v-flex xs4>
            <v-img
              style="border-radius: 8px"
              :src="photos[0].thumbnail"
              :alt="photos[0].thumbnail"
              height="120px"
              width="100%"
            >
            </v-img>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="(image, i) in lastPhoto" :key="i" xs4 sm3>
            <v-card class="black--text">
              <v-img
                style="border-radius: 8px"
                :src="image.thumbnail"
                :alt="image.thumbnail"
                height="120px"
                width="100%"
              >
              </v-img>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
import axios from "axios";
const api = "https://balitripcenter.com/api/galleryfeature";

export default {
  data() {
    return {
      loading: false,
      photos: [],
      errors: [],
    };
  },
  created() {
    this.getInitialPosts();
  },
  methods: {
    getInitialPosts() {
      this.loading = true;
      axios
        .get(api)
        .then((response) => {
          this.loading = false;
          this.photos = response.data.items;
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
  },
  computed: {
    lastPhoto() {
      return this.photos.slice(1);
    },
  },
};
</script>
<style scoped>
.gallery {
  background-color: #e5f3fe;
  padding: 8px;
  border-radius: 8px;
}
</style>