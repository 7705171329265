<template>
  <div>
    <v-card
      elevation="4"
      class="rounded black--text"
      :to="{ name: 'activity', params: { slug: post.slug,type: post.type.slug } }"
    >
      <v-img
        :src="post.images[0].thumbnail"
        :alt="post.images[0].thumbnail"
        height="50%"
        width="100%"
      >
      </v-img>
      <v-card-text>
        <v-layout row>
          <v-flex xs12>
            <div>
              <b class="title black--text">{{ post.name }}</b
              ><br />
              <div class="short_des">{{ post.short_des }}</div>
              <v-flex d-inline-flex pa-0 my-2 mr-2>
                <span class="rating">{{ $t('post-rating', [post.rating]) }}</span>
                <v-rating
                  class="mt-1"
                  v-model="post.rating"
                  background-color="gray"
                  color="black accent-4"
                  dense
                  size="18"
                ></v-rating> </v-flex
              ><br />
              <v-layout row wrap pa-2>
                <v-flex xs6 pa-0>
                  <small class="mr-2"
                    ><v-icon size="14">mdi-map-marker</v-icon
                    > {{ post.location }}</small
                  >
                  <small
                    ><v-icon size="14">mdi-clock</v-icon
                    > {{ $t('hours',[post.duration]) }}</small
                  > <br />
                  <!-- <small class="caption">{{post.visitor}} Visitor | {{post.comments_count}} Review</small><br> -->
                  <small
                    ><v-icon color="red" size="16">mdi-close-circle-outline</v-icon> {{ $t('free-cancelation-post-cancelation', [post.cancelation]) }}</small
                  >
                </v-flex>
                <v-flex xs6>
                  <span class="caption float-right">{{ $t('price') }} {{ post.price_type}}</span><br />
                  <div class="float-right">
                    <small class="price-orange">
                      {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</small
                    >
                    <span class="price-orange">
                      {{
                        $root.$i18n.locale == "id"
                          ? post.price_idr
                          : post.price_usd | fm
                      }}</span
                    >
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.fill {
  min-height: 50px;
}
.my-2 {
  margin: 8px 0;
}
.rating {
  background-color: black;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 20px;
  width: 60px;
  font-size: 12px;
  max-width: 60px;
}

.price-orange {
  color: #FF6100;
  font-size: 22px;
  font-weight: 500;
}

.rounded {
  border-radius: 14px !important;
}

.short_des {
  line-height: normal;
  background-color: #E7F2F8;
  padding: 8px;
  border-radius: 8px;
}
</style>
