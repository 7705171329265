<template>
  <div>
    <v-flex xs12 text-center py-4>
      <img
        src="@/assets/images/logo_gray.png"
        height="50px"
        width="150px"
        alt="$t('balitripcenter')"
      /><br />
      <div class="subheading"
        >{{ $t('book-our-service-with-many-reason') }}</div
      >
    </v-flex>
    <div v-for="(item, index) in items" :key="index">
      <v-row>
        <v-col cols="2 mr-2">
          <img class="img" :src="item.avatar" />
        </v-col>
        <v-col cols="9">
          <span class="my-title">{{ item.title }}</span
          ><br />
          <small class="my-subtitle">{{ item.subtitle }}</small></v-col
        >
      </v-row>
    </div>
  </div>
</template>
<script>
import sunset from "@/assets/images/sunset_xs.png";
import driver from "@/assets/images/driver_xs.png";
import price from "@/assets/images/price_xs.png";
import bali from "@/assets/images/bali_xs.png";

export default {
  /* eslint-disable */
  data() {
    return {
      items: [
        {
          avatar: sunset,
          title: this.$t('many-varienty-of-activities'),
          subtitle: this.$t('offer-more-than-100-tour-and-activities'),
        },
        {
          avatar: driver,
          title: this.$t('driver-experience'),
          subtitle:
            this.$t('all-our-driver-have-license-and-experience-to-excord-your-destination-with-best-route'),
        },
        {
          avatar: price,
          title: this.$t('lower-price'),
          subtitle: this.$t('you-found-chiper-price-let-me-know-to-get-best-deal'),
        },
        {
          avatar: bali,
          title: this.$t('local-operator'),
          subtitle: this.$t('we-operate-our-own-tour-and-activities-in-bali'),
        },
      ],
    };
  },
};
</script>
<style scoped>
.img {
  width: 65px;
  height: auto;
}

.my-title {
  font-weight: bold;
  font-size: 14px;
}
</style>

