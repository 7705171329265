<template>
    <v-card
      elevation="4"
      class="rounded black--text review-card"
    >
      <div class="d-flex" style="justify-content: space-between;margin-bottom: 15px;">
        <div class="d-flex" style="gap:10px">
            <v-img
              :src="post.user.image"
              :alt="post.user.image"
              height="50px"
              width="50px"
              class="rounded-circle"
            >
            </v-img>
            <div>
                <h4>{{ post.user.name }}</h4>
                <i class="mdi mdi-star" style="color: orange;"></i> <b>{{ post.rating }} <small>/5</small></b>
            </div>
        </div>

        <div>
            <small>{{ post.updated_at | showDate }}</small>
        </div>


      </div>
  
      <b>{{ post.commentable.name }}</b>
      <p v-html="post.body"></p>
  
    </v-card>
</template>
<script>
export default {
    props: ["post"],
};
</script>
<style scoped>
.review-card {
    padding: 10px;
}
</style>