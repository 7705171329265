<template>
  <div>
    <v-flex text pa-0>
      <img
        src="@/assets/images/auth-hero.jpg"
        height="auto"
        width="100%"
        alt="$t('login')"
      /><br />
    </v-flex>
    <v-container fluid grid-list-md>
      <v-layout row wrap pa-2>
        <v-flex xs12 text-center>
          <span class="title">{{ $t('login-with') }}</span>
        </v-flex>
        <social-button @success="success"></social-button>
        <v-flex xs12 text-center mt-2>
          <span class="subheading">{{ $t('or') }}</span>
        </v-flex>
        <v-flex xs12 sm8 offset-sm2 md4 offset-md4>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              type="password"
              :rules="passwordRules"
              :label="$t('password')"
              required
            ></v-text-field>
            <v-btn
              color="green"
              class="white--text"
              block
              :loading="loading"
              :disabled="!valid"
              @click="submit"
            >
              {{ $t('login') }} </v-btn>
          </v-form>
        </v-flex>
        <v-flex mt-4 xs12 sm8 offset-sm2 md4 offset-md4>
          {{ $t('dont-have-an-account') }} <v-btn outlined color="blue" to="register">{{ $t('register') }}</v-btn>
        </v-flex>
      </v-layout>
      <v-snackbar v-model="snackbar" :color="'red'" :timeout="-1">
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn text @click="snackbar = false" v-bind="attrs"> {{ $t('close') }} </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
    return {
    valid: false,
    password: "",
    passwordRules: [(v) => !!v || this.$t('password-is-required')],
    email: "",
    emailRules: [
      (v) => !!v || this.$t('e-mail-is-required'),
      (v) => /.+@.+/.test(v) || this.$t('e-mail-must-be-valid'),
    ],
    loading: false,
    snackbar: false,
    text: null,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .get(`https://balitripcenter.com/sanctum/csrf-cookie`)
          .then((res) => {
            axios
              .post(`https://balitripcenter.com/api/login`, {
                email: this.email,
                password: this.password,
              })
              .then((response) => {
                this.loading = false;
                this.success(response);
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl);
                } else if (this.$route.params.nextUrl === "activity") {
                  this.$router.back();
                } else {
                  this.$router.push("/");
                }
                //console.log(response.data);
              })
              .catch((e) => {
                //console.log(e.response);
                this.loading = false;
                this.text = e.response.data.message;
                this.snackbar = true;
                // todo show error
                // this.errors.push(e)
              });
          });
      }
    },
    success(response) {
      this.$store.dispatch("setToken", {
        token: response.data.token,
      });
      this.$store.dispatch("setProfile", {
        profile: response.data.user,
      });
      if (this.$route.params.nextUrl != null) {
        this.$router.push(this.$route.params.nextUrl);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
