<template>
  <v-flex xs12 pa-2>
    <span class="body-2">{{ $t('how-to-booking') }}</span>
    <ul>
        <li>{{ $t('choose-which-bali-trip-center-package-you-want-to-book') }}</li>
        <li>{{ $t('klik-book-and-fill-the') }}</li>
        <li>{{ $t('after-we-received-your') }}</li>
        <li>{{ $t('fill-the-form-with-hotel') }}</li>
        <li>{{ $t('after-everything-is-clear') }}</li>
    </ul>
  </v-flex>
</template>

<script>
export default {
  
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  }

  li {
    padding-left: 1em; 
    text-indent: -.7em;
    text-align: justify;
  }

  li::before {
    content: "•";
    color: rgb(255, 0, 0);
    vertical-align: middle;
    font-size: 20px;
    margin-right: 2px;
  }
</style>
