<template>
  <div v-if="destinations && destinations.length">
    <v-container fluid grid-list-md pa-0>
      <v-layout row wrap>
        <v-flex v-for="(post, i) of destinations" :key="i" xs12 sm6 xl4>
          <home-destination-card :post="post"></home-destination-card>
        </v-flex>
        <v-btn
          class="mt-2"
          block
          rounded
          outlined
          color="#29D488"
          dark
          to="destination"
          >{{ $t('see-all') }}</v-btn
        >
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["destinations"],
};
</script>
<style scoped>
img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>