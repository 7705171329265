<template>
  <div>
    <div v-if="!loading">
      <div style="padding: 4px">
        <div class="body-2 hero-title">
          <img
            class="hero-icon"
            :src="category.images[0].original"
            alt="english"
          />
          <div class="hero-text">{{ category.name }}</div>
        </div>
        <small class="hero-subtext">{{ category.title }}</small>
      </div>
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
                <home-activitiy-card
                  class="card"
                  :post="post"
                ></home-activitiy-card>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
      <center class="mt-1">
        <v-btn to="activity" outlined rounded elevation="0" color="primary"
          >{{ $t('explore-more') }}</v-btn
        >
      </center>
    </div>
    <loading :loading="loading"></loading>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/type/1`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
          //console.log(response.data.category)
        })
        .catch((e) => {
          this.loading = false;
          // this.errors.push(e)
        });
    },
  },
};
</script>
<style scoped>
.hero-title {
  color: #1ecba2;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
}

.hero-title img {
  margin: 4px 12px 4px 4px;
  width: 30px;
  height: auto;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  font-weight: 600;
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: gray;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .card {
  display: inline-block;
  width: 260px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.yellow-bar {
  height: 4px;
  background-color: #ff6100;
  width: 10%;
}
.white-bar {
  height: 4px;
  background-color: #ddd;
  width: 40%;
  margin: 0 6px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

img {
  width: 100%;
  height: 200px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>