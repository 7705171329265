<template>
  <v-card
    class="black--text"
    :to="{ name: 'activity', params: { slug: post.slug,type: post.type.slug } }"
  >
    <v-container fluid grid-list-lg pa-2>
      <v-layout row>
        <v-flex xs4>
          <v-img
            :src="post.images[0].thumbnail"
            :alt="post.images[0].thumbnail"
            height="100%"
            width="100%"
          >
          </v-img>
        </v-flex>
        <v-flex xs8 pa-1>
          <div>
            <span class="subheading">{{ post.name }}</span
            ><br />
            <v-flex d-inline-flex pa-0>
              <v-rating
                v-model="post.rating"
                background-color="gray"
                color="yellow accent-4"
                dense
                size="15"
              ></v-rating>
              <span style="margin-left: 4px">{{
                post.comments_count
              }}</span> </v-flex
            ><br />
            <small class="caption">{{ $t('duration-post-duration', [post.duration]) }}</small>
            <v-flex xs12 class="rigth" pr-2>
              <span class="gray--text">{{ $t('from') }}</span><br />
              <div>
                <small class="top black--text">
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</small
                >
                <span class="title black--text">
                  {{
                    $root.$i18n.locale == "id" ? post.price_idr : post.price_usd | fm
                  }}</span
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.rigth {
  text-align: right !important;
}
.top {
  vertical-align: top;
}
</style>
