<template>
  <v-layout pa-2>
    <v-flex xs6>
      <div>
        <h4 class="box">{{ $t('activities') }}</h4>
        <ul>
          <li v-for="(type, i) in items.types" :key="i">
            <!-- <router-link :to="{ name: 'activities', params: { type: type.id }}"> -->
            <div class="small">{{ type.name }}</div>
            <!-- </router-link> -->
          </li>
        </ul>
      </div>
    </v-flex>
    <v-flex xs6>
      <div>
        <h4 class="box">{{ $t('destinations') }}</h4>
        <ul>
          <li v-for="(regency, i) in items.regencies" :key="i">
            <!-- <router-link :to="{ name: 'destinations', params: { type: regency.id }}"> -->
            <div class="small">{{ regency.name }}</div>
            <!-- </router-link> -->
          </li>
        </ul>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: ["items"],
};
</script>
<style scoped>
.small {
  font-size: 14px;
  padding: 1px 0;
  text-transform: lowercase;
}

.small:first-letter {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
  padding-left: 0px !important;
}
.box {
  background-color: #e7f2f8;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 4px;
  margin-bottom: 8px;
  color: #333;
}
</style>
