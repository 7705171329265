<template>
  <div v-show="loading" class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>
<script>
export default {
  props: ["loading"],
};
</script>

