<template>
  <v-flex xs12 pa-2>
    <span class="body-2">{{ $t('term-payment') }}</span>
    <ul>
        <li>{{ $t('payment-is-cash-payment-or-bank-transfer') }}</li>
        <li>{{ $t('payment-is-on-the-day-itself-with-our-driver') }}</li>
        <li>{{ $t('payment-with-other-currency-will-convert-based-on-daily-exchange-rate') }}</li>
    </ul>
  </v-flex>
</template>
<script>
export default {
  
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding-left: 1em; 
  text-indent: -.7em;
  text-align: justify;
}

li::before {
  content: "•";
  color: rgb(255, 0, 0);
  vertical-align: middle;
  font-size: 20px;
  margin-right: 2px;
}
</style>
