<template>
  <div>
    <center>
      <img
        src="@/assets/images/rental-kendaraan.png"
        height="auto"
        width="90%"
        alt="transport"
      />
      <div class="text-subtitle-1" style="color: #8cc0cd">{{ $t('rental-kendaraan') }}</div>
      <div style="color: grey">
        {{ $t('mau-mengexplorasi-bali-tanpa') }} </div>
      <br />
    </center>
    <div v-if="!loading">
      <div v-if="items && items.length">
        <v-container fluid grid-list-md pa-1>
          <v-layout row wrap>
            <div class="scrolling-wrapper">
              <v-flex v-for="(post, i) of items" :key="i" xs12 sm6 xl4>
                <router-link
                  :to="{ name: 'activity', params: { slug: post.slug,type: post.type.slug } }"
                >
                  <div class="box-x">
                    <v-img
                      class="box-img"
                      :src="post.images[0].thumbnail"
                      :alt="post.images[0].thumbnail"
                      height="200px"
                      width="100%"
                    >
                    </v-img>
                    <div class="center-title">{{ post.name }}</div>
                    <div class="btn-orange">{{ $t('pesan-sekarang') }}</div>
                  </div>
                </router-link>
              </v-flex>
            </div>
          </v-layout>
        </v-container>
      </div>
      <center v-else>{{ $t('tidak-ada-transport') }}</center>
      <!-- <center class="mt-2">
        <v-btn to="activity" outlined rounded elevation="0" color="#8BC0CC"
          >EXPLORE MORE</v-btn
        >
      </center> -->
    </div>
    <loading :loading="loading"></loading>
    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      items: [],
      errors: [],
      category: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/api/type/15`)
        .then((response) => {
          this.loading = false;
          this.items = response.data.items.data;
          this.category = response.data.category;
          //console.log(response.data.category)
        })
        .catch((e) => {
          this.loading = false;
          // this.errors.push(e)
        });
    },
  },
};
</script>

<style scoped>
.hero-title {
  color: #1ecba2;
  position: relative;
  margin: auto;
  padding: auto;
  vertical-align: middle;
}

.hero-title img {
  margin: 4px 12px 4px 4px;
  width: 30px;
  height: auto;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 13%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-icon {
  width: 40px;
  height: auto;
}

.hero-subtext {
  font-size: 14px;
  color: gray;
}

.scrolling-wrapper {
  display: flex;
  margin: 0 -13px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.scrolling-wrapper .box-x {
  display: inline-block;
  width: 150px;
  max-width: 100%;
  height: auto;
  flex: 0 0 auto;
  scroll-snap-align: center;
  margin-left: 8px;
}

.h-inline {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.box-img {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.box-x {
  position: relative;
}

.center-title {
  bottom: 20%;
  text-align: center;
  font-weight: 600;
  padding-left: 8px;
  color: white;
  position: absolute;
}

.btn-orange {
  display: block;
  background-color: #fd6301;
  padding: 8px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: white;
  text-align: center;
}
</style>