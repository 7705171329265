<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click.native="cancel">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('login') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md pa-2>
          <v-layout row wrap>
            <v-flex xs12 text-center>
              <span class="subheading">{{ $t('login-with') }}</span>
            </v-flex>
            <social-button @success="success"></social-button>
            <v-flex xs12 text-center mt-2>
              <span class="subheading">{{ $t('or') }}</span>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 md4 offset-md4>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  type="password"
                  :rules="passwordRules"
                  :label="$t('password')"
                  required
                ></v-text-field>
                <v-btn
                  color="green"
                  class="white--text"
                  block
                  :loading="loading"
                  :disabled="!valid"
                  @click="submit"
                >
                  {{ $t('login') }} </v-btn>
              </v-form>
            </v-flex>
            <!-- <v-flex xs12 sm8 offset-sm2 md4 offset-md4>
              Don't have an account?
              <v-btn outlined color="blue" to="register">Register</v-btn>
            </v-flex> -->
          </v-layout>
          <v-snackbar v-model="snackbar" :color="'red'" :timeout="-1">
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn text @click="snackbar = false" v-bind="attrs">
                {{ $t('close') }} </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";

export default {
  props: ["dialog"],
    data() {
    return {
    valid: false,
    password: "",
    passwordRules: [(v) => !!v || this.$t('password-is-required')],
    email: "",
    emailRules: [
      (v) => !!v || this.$t('e-mail-is-required'),
      (v) => /.+@.+/.test(v) || this.$t('e-mail-must-be-valid'),
    ],
    loading: false,
    snackbar: false,
    text: null,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        axios
          .get(`https://balitripcenter.com/sanctum/csrf-cookie`)
          .then((res) => {
            axios
              .post(`https://balitripcenter.com/api/login`, {
                email: this.email,
                password: this.password,
              })
              .then((response) => {
                this.loading = false;
                this.success(response);
              })
              .catch((e) => {
                this.loading = false;
              });
          });
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    success(response) {
      this.$store.dispatch("setToken", {
        token: response.data.token,
      });
      this.$store.dispatch("setProfile", {
        profile: response.data.user,
      });
      this.$emit("loginSuccess");
    },
  },
};
</script>
