<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense>
        <v-btn icon @click.native="cancel">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('confirmation') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn outlined @click.native="edit">
          Edit
        </v-btn> -->
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md pa-0>
          <v-layout row wrap>
            <v-flex xs6c>
              <div class="top-box">
                <span class="n-gray"> 1 </span>
                <span style="color: #aaa">{{ $t('booking-form') }}</span>
              </div>
            </v-flex>
            <v-flex xs6>
              <div class="top-box">
                <b class="n-orange"> 2 </b>
                <b style="color: #000">{{ $t('booking-confirmation') }}</b>
              </div>
            </v-flex>
          </v-layout>

          <img class="img--hero" :src="post.images[0].original" alt="" />
          <!-- <v-carousel class="carousel-img" hide-delimiters >
            <v-carousel-item v-for="(image,i) in post.images"  :key="i">
              <img class="img" :src="image.original" alt="" @click="dialog = true">
            </v-carousel-item>
          </v-carousel> -->
          <!-- <v-carousel hide-delimiters height="auto">
            <v-carousel-item
              v-for="(image,i) in post.images"
              :key="i"
              :src="image.original"
              reverse-transition="fade"
              transition="fade"
              @click="dialog = true"
            ></v-carousel-item>
          </v-carousel> -->
          <span class="post-name">{{ post.name }}</span>
          <table class="table--detail">
            <tr>
              <td>{{ $t('date') }}</td>
              <td>{{ $t('data-date', [bookingdata.date]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('duration') }}</td>
              <td>{{ $t('post-duration', [post.duration]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('location') }}</td>
              <td>{{ $t('post-location', [post.location]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('product-id') }}</td>
              <td>{{ $t('post-code', [post.code]) }}</td>
            </tr>
            <tr>
              <td>{{ $t('cencelation') }}</td>
              <td>{{ $t('post-cancelation', [post.cancelation]) }}</td>
            </tr>
          </table>
          <br />
          <span class="conf--title"> {{ $t('price-breakdown') }} </span>
          <div v-for="(item, index) in orderFiltered" :key="index">
            <v-layout row wrap>
              {{ item.name }} 
            </v-layout>
            <v-layout row wrap>
              <v-flex xs6>
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }} {{ $root.$i18n.locale == "id" ? item.price_idr : item.price_usd | fm }} x {{ item.ordered }}
              </v-flex>
              <v-flex xs6>
                <span class="float-right">
                  {{ $root.$i18n.locale == "id" ? "= IDR " : "= USD " }}
                  {{ ($root.$i18n.locale == "id" ? item.price_idr : item.price_usd) * item.ordered | fm }}
                </span>
              </v-flex>
            </v-layout>
            <v-divider style="margin-top: 4px; margin-bottom: 4px"></v-divider>
          </div>

          <v-layout>
            <v-flex>
            <span class="float-right"
              >{{ $t('total-amount') }}
              <b>
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                {{ bookingdata.total | fm }}</b
              ></span
            >
            </v-flex>

          </v-layout>
          <div class="conf--title"> {{ $t('personal-information') }} </div>
          <table class="table--detail">
            <tr>
              <td>{{ bookingdata.first_name }} {{ bookingdata.last_name }}</td>
            </tr>
            <tr>
              <td>{{ bookingdata.country }}</td>
            </tr>
            <tr>
              <td>{{ bookingdata.phone_number }}</td>
            </tr>
            <tr>
              <td>{{ bookingdata.email }}</td>
            </tr>
          </table>
          <span class="conf--title"> {{ $t('pickup-information') }} </span>
          <span class="conf--pickup">{{ bookingdata.pickup_location }}</span
          ><br />
          <div class="location--info">
            {{ $t('kindly-to-share-pickup-location-with-our-driver-on-google-maps-to-easy-pickup-process') }} </div>
          <br />
          <v-layout row wrap my-4>
            <v-flex xs12>
              <span class="payment">{{ $t('payment') }}</span>
              {{ $t('your-payment-with') }} <b style="color: #20e683">{{ bookingdata.payment_method }}</b>
            </v-flex>
          </v-layout>
          <div class="payment--title">
            <span>{{ $t('payment-and-cancelation-policy-information') }}</span><br />
          </div>

          <ul class="paymet--list"></ul>
          <div v-for="item in payments" :key="item">
            <v-layout row wrap>
              <v-flex xs1>
                <v-icon>mdi-information-outline</v-icon>
              </v-flex>
              <v-flex xs11>
                <span class="payment--item">{{ item }}</span>
              </v-flex>
            </v-layout>
          </div>

          <div class="confidence--title">
            <span>{{ $t('booking-with-confidence') }}</span>
          </div>

          <div class="confidence--list" v-for="item in confidences" :key="item">
            <v-layout row wrap>
              <v-flex xs1>
                <v-icon color="green">mdi-check-bold</v-icon>
              </v-flex>
              <v-flex xs11>
                <span>{{ item }}</span>
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap>
            <v-flex xs12>
              <v-btn
                dark
                color="green white--text"
                block
                :loading="loading"
                @click="submit"
              >
                {{ $t('confirm-and-book-now') }} </v-btn>
              <div style="text-align: center">
                <small
                  >{{ $t('by-click-confirmation-buutton-you-also-agree-with-our') }}
                  <a href="#">{{ $t('term-payment') }}</a> and
                  <a href="#">{{ $t('cancelation-policy') }}</a></small
                >
              </div>
            </v-flex>
          </v-layout>
          <br />
          <h4>{{ $t('frequenlty-asked-questions') }}</h4>
          <!-- <div v-for="(item, index) in qa" :key="index"> -->
          <!-- <div class="box">
              <div class="q">{{item.q}}</div>
              <div class="a">{{item.a}}</div>
            </div> -->
          <!-- </div> -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-content v-for="(item, i) in qa" :key="i">
                <template v-slot:header>
                  <div>{{ item.q }}</div>
                </template>
                <v-card>
                  <v-card-text>{{ item.a }}</v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="red-box">
            <b
              ><v-icon color="black" size="18">mdi-lock</v-icon> {{ $t('data-security') }}y</b
            ><br />
            <span
              >{{ $t('your-data-are') }}.</span
            >
          </div>

          <v-snackbar v-model="snackbar" :color="snack_color">
            {{ snack_text }}
            <template v-slot:action="{ attrs }">
              <v-btn text @click="snackbar = false" v-bind="attrs">
                {{ $t('close') }} </v-btn></template
            >
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
/* eslint-disable */
export default {
  props: ["dialog","type", "post", "bookingdata"],
    data() {
      return {
        loading: false,
        snackbar: false,
        snack_text: null,
        snack_color: "red",
        orders: null,
        payments: [
          this.$t('all-rates-on-balitripcenter'),
          this.$t('payment-by-usd'),
          this.$t('we-only-accept-payment-in-rupiah-and-do-not-accept-cash-in-us-dollars'),
          this.$t('payment-of-the-cancellation-'),
        ],
        confidences: [
          this.$t('you-will-receive-a-confirmation-immediately-after-click-booking'),
          this.$t('no-deposit-required-book-now-and-pay-later'),
          this.$t('manage-by-bali-local-operator-more-expert-experiences'),
          this.$t('best-price-and-without-third-party'),
          this.$t('easy-costumize-with-your-favorite-activity'),
          this.$t('glady-to-answer-your-questions-and-adapt-your-budget-with-your-itinerary-request'),
          this.$t('no-hidden-cost'),
        ],
        qa: [
          {
            q: `1. Question: What currency does Bali Trip Center accept?`,
            a: `Answer: We accept USD. If you use a credit card, you will have to prepare Rupiah in cash for extra tips.`,
          },
          {
            q: `2. Question: Can I know the detail of the payment?`,
            a: `Answer: Yes, you can. You should select the package and the date of your arrival first, and then adjust the unit, and finally, you’ll see the final payment including the detail.`,
          },
          {
            q: `3. Question: Is booking charged?`,
            a: `Answer: If you pay cash, there’s no booking fee, but if you use a credit card, there is usually an extra fee for booking.`,
          },
          {
            q: `4. Question: What is the payment method?`,
            a: `Answer: You can pay cash, use a credit or debit card, and PayPal. There will be an instruction on how to do the payment in the email you receive.`,
          },
          {
            q: `5. Question: What if I pay cash?`,
            a: `Answer: You are welcome to pay cash. For Bali Tour Combination you can pay to our drivers.`,
          },
          {
            q: `6. Question: Is my payment safe?`,
            a: `Answer: Our online payment system is safe. It encrypts the information of your payment to prevent you from unauthorized transactions and from the frauds.`,
          },
          {
            q: `7. Question: Is there any discounts?`,
            a: `Answer: We give our clients the best price, but you may let us know the price that you want. We’ll consider your price if you choose a group tour.`,
          },
        ],
      };
  },
  methods: {
    submit() {
      this.loading = true;
      axios
        .get(`https://balitripcenter.com/sanctum/csrf-cookie`)
        .then((res) => {
          axios
            .post(
              `https://balitripcenter.com/api/activity/${this.type.slug}/${this.post.slug}/booking`,
              {
                activity_id: this.post.id,
                date: this.bookingdata.date,
                adults: this.bookingdata.adults,
                kids: this.bookingdata.kids,
                first_name: this.bookingdata.title + " " + this.bookingdata.first_name,
                last_name: this.bookingdata.last_name,
                email: this.bookingdata.email,
                phone_number: this.bookingdata.phone_number,
                country: this.bookingdata.country,
                special_request: this.bookingdata.special_request,
                pickup_location: this.bookingdata.pickup_location,
                payment_method: this.bookingdata.payment_method,
                order_data: JSON.stringify(this.bookingdata.order_data),
                total: this.bookingdata.total,
              }
            )
            .then((response) => {
              this.loading = false;
              this.success(response);
            })
            .catch((e) => {
              this.snackbar = true;
              this.loading = false;
              this.snack_color = "red";
              this.snack_text = this.$t('something-went-wrong-please-reload');
            });
        });
    },
    cancel() {
      this.$emit("confirmCancel");
    },
    success(response) {
      this.$emit("confirmSuccess", response);
    },
  },
  computed: {
    orderFiltered() {
      return this.bookingdata.order_data.filter((value) => {
        return value.ordered > 0;
      });
    },
  },
};
</script>

<style scoped>
.top-box {
  background-color: #eee;
  height: 100%;
  padding: 4px;
  font-size: 12px;
}

.n-orange {
  background-color: #ff6100;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
}
.n-gray {
  background-color: #aaa;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  height: 24px;
  margin-right: 8px;
  min-width: 24px;
  width: 24px;
}

.box {
  background-color: #e6e4e5;
  padding: 9px;
  margin: 4px 0;
  font-size: 12px;
}

.box .q {
  color: black;
}

.box .a {
  color: gray;
}

.red-box {
  background-color: #f6d0a3;
  padding: 9px;
  margin: 12px 0;
  font-size: 12px;
}

a {
  color: green;
}

.location--info {
  color: #bbbbbb;
  font-size: 12px;
  text-align: center;
}

.conf--title {
  font-size: 16px;
  display: block;
  padding: 4px;
  text-align: center;
  background-color: #ddd;
  margin: 12px 0;
  border-radius: 4px;
}
.payment {
  font-size: 14px;
  padding: 4px 8px;
  text-align: center;
  background-color: #ddd;
  margin: 8px 0;
  border-radius: 4px;
}
.conf--pickup {
  padding: 12px;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: #dddddd;
  border-radius: 4px;
}

.table--detail table {
  border-collapse: collapse;
}
.table--detail tr {
  border: solid;
  border-color: #ddd;
  border-width: 1px 0;
}

.table--detail tr:first-child {
  border-top: none;
}
.table--detail tr:last-child {
  border-bottom: none;
}

.post-name {
  margin: 12px 0;
  font-size: 18px;
  font-weight: bold;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 960px;
    object-fit: cover;
  }
}
.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
}
.img--hero {
  max-width: 100%;
  height: auto;
}

.title_bg {
  display: block;
  margin-left: -16px;
  margin-right: -16px;
  padding: 8px;
  margin-top: 8px;
  background-color: #ddd;
  border-width: 0 0 0 8px;
  border-style: solid;
  border-color: #ff6100;
}

.agree {
  text-align: center;
  font-size: 10px;
}
.payment--title {
  color: black;
  font-size: 16px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 4px;
}

.payment--list {
  background-color: #e3fcf6;
  margin-left: -16px;
  margin-right: -16px;
  padding: 12px;
}

.payment--list ul {
  list-style-image: url("~@/assets/images/check.svg");
  font-size: 12px;
  color: #ddd;
}

.payment--item {
  display: block;
  background-color: #ddd;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  font-size: 12px;
  padding: 4px;
}

.confidence--title {
  color: green;
  font-size: 18px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 4px;
}

.confidence--list {
  background-color: #e3fcf6;
  margin-left: -16px;
  margin-right: -16px;
  padding: 4px 12px;
}

.bottom--booking {
  margin: -16px;
  border-top-width: 2px;
  border-top-style: solid;
  border-color: #37474f;
  margin-top: 12px;
}
.bottom--booking span,
small {
  padding-left: 16px;
}
.btn--button {
  height: 100%;
  width: 100%;
  font-size: 18px;
  display: block;
  background-color: #37474f;
  color: white;
}
</style>

