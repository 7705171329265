<template>
  <div>
    <loading :loading="loading"></loading>
    <div v-if="post" v-show="!loading">
      <v-carousel hide-delimiters height="260px">
        <v-carousel-item
          v-for="(image, i) in post.images"
          :key="i"
          :src="image.original"
          reverse-transition="fade"
          transition="fade"
          @click="dialog = true"
        ></v-carousel-item>
      </v-carousel>
      <v-container pa-2 fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 md7>
            <!-- <v-carousel class="carousel-img" hide-delimiters>
            <v-carousel-item v-for="(image,i) in post.images" :src="image.original" :key="i">
              <img class="img" :src="image.original" alt="" @click="dialog = true">
            </v-carousel-item>
          </v-carousel> -->
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card color="grey darken-4">
                <v-flex xs12>
                  <v-btn icon dark @click.native="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 text-sm-center class="center">
                  <v-carousel class="carousel-img" hide-delimiters>
                    <v-carousel-item v-for="(image, i) in post.images" :key="i">
                      <img class="img" :src="image.original" alt="" />
                    </v-carousel-item>
                  </v-carousel>
                </v-flex>
              </v-card>
            </v-dialog>
            <v-layout row wrap>
              <v-flex xs12 py-2>
                <span class="title">{{
                  post.manufacture + " " + post.vehicle_name
                }}</span>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs7 d-inline-flex>
                <div class="rating">{{ $t('post-rating', [post.rating]) }}</div>
                <v-rating
                  v-model="post.rating"
                  background-color="gray"
                  color="yellow accent-4"
                  dense
                  size="12"
                ></v-rating>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs8 d-inline-flex class="v-center">
                <small
                  >{{ $t('post-visitor-visitor-or-post-comments_count-review', [post.visitor, post.comments_count]) }}w</small
                >
              </v-flex>
              <v-flex xs-4 d-inline-flex class="v-center h-center">
                <h2 class="green--text">
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
                  {{ post.price | fm }}
                </h2>
              </v-flex>
            </v-layout>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <b slot="header">{{ $t('detail-information') }}</b>
                <transport-detail :post="post" />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-layout row wrap>
              <v-flex xs12 d-inline-flex>
                <v-tabs slider-color="deep-orange">
                  <v-tab ripple> DESCRIPTION </v-tab>
                  <v-tab ripple> REVIEWS </v-tab>
                  <v-tab ripple> FAQ </v-tab>
                  <v-tab-item>
                    <v-card>
                      <v-card-text v-html="post.des"></v-card-text>
                    </v-card>
                    <br />
                    <v-card>
                      <v-card-text>
                        <div>
                          <b class="deep-orange--text text-darken-1">{{ $t('note') }}</b
                          ><br />
                          <v-flex v-html="post.note"></v-flex><br />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card>
                      <v-card-text>
                        <loading :loading="commentsloading"></loading>
                        <div v-show="!commentsloading">
                          <div v-if="comments.length">
                            <comment-header
                              :avg="overview.avg"
                            ></comment-header>
                            <v-btn color="primary" block @click="isUser"
                              >{{ $t('write-review') }}</v-btn
                            ><br />
                            <span class="title">{{ $t('reviews') }}</span>
                            <comment-overview
                              :overview="overview"
                            ></comment-overview>
                            <comment-item :comments="comments" />
                          </div>
                          <div v-else>
                            <v-btn color="primary" block @click="isUser"
                              >{{ $t('write-review') }}</v-btn
                            ><br />
                            <v-flex xs12 text-center mt-2>
                              {{ $t('this-transport-dont-have-any-comment-yet') }} </v-flex>
                          </div>
                        </div>
                        <comment-dialog
                          :dialog="commentDialog"
                          :url="url"
                          @cancelDialog="commentDialog = false"
                          @commentSuccess="commentSuccess"
                        ></comment-dialog>
                        <login-dialog
                          :dialog="loginDialog"
                          @loginSuccess="loginSuccess"
                          @cancel="loginDialog = false"
                        ></login-dialog>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item><faq /> </v-tab-item>
                </v-tabs>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md5>
            <v-flex mt-2>
              <span class="subheading black--text"
                >{{ $t('discover-more-transportation') }}</span
              >
            </v-flex>
            <v-flex v-for="(post, i) of others" :key="i">
              <destination-card :post="post"></destination-card>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <v-flex class="sticky" pa-0 xs12 background-color="white">
        <v-divider></v-divider>
        <v-container grid-list-xs>
          <v-layout row wrap pa-1 pl-2>
            <v-flex xs7>
              <small>{{ $t('starting-from') }}</small><br />
              <span class="top green--text">
                {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</span
              >
              <span class="headline green--text">{{ post.price | fm }}</span>
              <small>/Person</small>
            </v-flex>
            <v-flex xs5>
              <v-btn color="grey darken-2 white--text" block>{{ $t('book-now') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      commentsloading: false,
      post: null,
      others: [],
      errors: [],
      comments: [],
      commentDialog: false,
      loginDialog: false,
      overview: null,
      dialog: false,
    };
  },
  created() {
    this.fetchData();
    this.getComments();
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get(
          `https://balitripcenter.com/api/transport/` + this.$route.params.id
        )
        .then((response) => {
          this.loading = false;
          this.post = response.data.items;
          this.others = response.data.others;
          //console.log(response.data.items);
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getComments() {
      this.commentsloading = true;
      axios
        .get(
          `https://balitripcenter.com/api/transport/` +
            this.$route.params.id +
            "/comment"
        )
        .then((response) => {
          this.commentsloading = false;
          this.comments = response.data.items;
          this.overview = response.data.overview;
          //console.log(response.data.items);
        })
        .catch((e) => {
          this.commentsloading = false;
          this.errors.push(e);
        });
    },
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
    loginSuccess() {
      this.loginDialog = false;
      this.commentDialog = true;
    },
    commentSuccess() {
      this.commentDialog = false;
      this.getComments();
    },
  },
  computed: {
    url() {
      return (
        `https://balitripcenter.com/api/transport/` +
        this.$route.params.id +
        "/comment="
      );
    },
  },
};
</script>
<style scoped>
.top {
  vertical-align: top;
}
.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}
.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 2560px) {
  .carousel-img {
    width: 100%;
    height: 960px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    width: 100%;
    height: 960px;
    object-fit: cover;
  }
}

.v-center {
  vertical-align: middle;
  line-height: 30px;
}
.h-center {
  text-align: center;
}
.right {
  text-align: right;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
</style>