<template>
  <center>
    <br />
    <img
      src="@/assets/images/about-bali.png"
      height="auto"
      width="90%"
      alt="transport"
    />
    <div class="text-h6 orange--text">{{ $t('siapa-kami') }}</div>
    <div class="divider"></div>
    <div style="color: grey">
      {{ $t('balitripcenter-adalah-operator-iokal-bali') }} </div>
  </center>
</template>

<script>
export default {
  
}
</script>

<style>
.divider {
  width: 200px;
  height: 4px;
  background-color: orange;
  margin-bottom: 20px;
}
</style>